import {useEffect} from 'react';
import type {NextPage} from 'next';
import type {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import {Toaster} from 'react-hot-toast';
import {Provider as ReduxProvider} from 'react-redux';
import nProgress from 'nprogress';
import {CacheProvider} from '@emotion/react';
import type {EmotionCache} from '@emotion/cache';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {RTL} from '../components/rtl';
import {SettingsButton} from '../components/settings-button';
import {SplashScreen} from '../components/splash-screen';
import {SettingsConsumer, SettingsProvider} from '../contexts/settings-context';
import {gtmConfig} from '../config';
import {gtm} from '../lib/gtm';
import {store} from '../store';
import {createTheme} from '../theme';
import {createEmotionCache} from '../utils/create-emotion-cache';
import '../i18n';
import {SSRCookies, SSRKeycloakProvider} from '@react-keycloak/ssr'
import {IncomingMessage} from 'http'
import cookie from 'cookie'
import env from '@beam-australia/react-env';
import HeliumApiProvider from "../contexts/helium-api-context";

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
  cookies: unknown;
}

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const keycloakConfig = {
  url: env('KEYCLOAK_URL'),
  realm: env('KEYCLOAK_REALM'),
  clientId: env('KEYCLOAK_CLIENT_ID'),
}

const App = (props: EnhancedAppProps) => {
  const {Component, emotionCache = clientSideEmotionCache, pageProps, cookies} = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  // @ts-ignore
  const checkLoginIframe = typeof window !== "undefined" && !window.Cypress;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>
          Material Kit Pro
        </title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />
      </Head>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SSRKeycloakProvider
            keycloakConfig={keycloakConfig}
            initOptions={{
              onLoad: 'check-sso',
              silentCheckSsoRedirectUri: `${env('BASE_URL')}/silent-check-sso.html`,
              checkLoginIframe,
            }}
            persistor={SSRCookies(cookies)}
            LoadingComponent={<SplashScreen />}>
            <SettingsProvider>
              <SettingsConsumer>
                {({settings}) => (
                  <ThemeProvider
                    theme={createTheme({
                      direction: settings.direction,
                      responsiveFontSizes: settings.responsiveFontSizes,
                      mode: settings.theme
                    })}
                  >
                    <RTL direction={settings.direction}>
                      <CssBaseline />
                      <Toaster position="top-center" />
                      {/*<SettingsButton />*/}
                      <HeliumApiProvider>
                        {getLayout(<Component {...pageProps} />)}
                      </HeliumApiProvider>
                    </RTL>
                  </ThemeProvider>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </SSRKeycloakProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </CacheProvider>
  );
};

function parseCookies(req?: IncomingMessage) {
  if (!req || !req.headers) {
    return {}
  }
  return cookie.parse(req.headers.cookie || '')
}


App.getInitialProps = async (context: AppContext) => {
  // Extract cookies from AppContext
  return {
    cookies: parseCookies(context?.ctx?.req),
  }
}

export default App;
