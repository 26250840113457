import { createContext, useContext, useEffect, useState } from 'react';
import env from '@beam-australia/react-env';
import {Configuration, InvestorsApi, ProjectsApi} from "@zymlabs/helium-axios-sdk";
import {useKeycloak} from "@react-keycloak/ssr";
import {KeycloakInstance} from "keycloak-js";

interface HeliumApiInterface {
  investorsApi: InvestorsApi;
  projectsApi: ProjectsApi;

}

const HeliumApiContext = createContext<HeliumApiInterface>({
  investorsApi: new InvestorsApi(),
  projectsApi: new ProjectsApi()
});

const HeliumApiProvider = ({ children }: any) => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();

  const [api, setApi] = useState<HeliumApiInterface>({
    investorsApi: new InvestorsApi(),
    projectsApi: new ProjectsApi()
  });

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      const configuration = new Configuration({
        basePath: env('HELIUM_API_URL'),
        accessToken: keycloak.token
      });

      setApi({
        investorsApi: new InvestorsApi(configuration),
        projectsApi: new ProjectsApi(configuration)
      });

    }
  }, [initialized, keycloak?.authenticated, keycloak?.token]);

  return (
    <HeliumApiContext.Provider
      value={api}
    >
      {children}
    </HeliumApiContext.Provider>
  );
};

export default HeliumApiProvider;

export const useHelium = () => useContext(HeliumApiContext);
